import React, {
  useContext,
  useState,
  lazy,
  Suspense,
  useEffect,
  useRef,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography, CircularProgress } from '@mui/material';
import moment from 'moment';
import styled from '@emotion/styled';

import { Row, Spacer, Tabs, DateBox, DateRangeOption } from 'components';
import {
  useSpot,
  useTabFromQuerystring,
  RetailerContext,
  RoleContext
} from 'framework';
import { ExperimentStats } from './experiment-stats';

const GlobalStats = lazy(() => import('./global-stats'));
const DataExport = lazy(() => import('./data-export'));

const StoreStatus = lazy(() => import('./store-status'));
const ReturnsCsvImport = lazy(() => import('./returns-import'));
const Realtime = lazy(() => import('./realtime'));
const EnrichedOrdersOverview = lazy(() => import('./enriched-orders-overview'));

const GlobalStatsFallback = styled(Box)`
  height: 3800px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export function Landing() {
  const { t } = useTranslation();
  const { spot, loading } = useSpot();

  const { hasRole } = useContext(RoleContext);

  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());
  const dateRange = useRef<DateRangeOption>();

  const { retailer } = useContext(RetailerContext);

  const { tab, setTab } = useTabFromQuerystring('stats');

  if (!spot.data.retailers?.length) {
    return <>{t('loading')}</>;
  }

  const setDateRange = useCallback(
    (newDateRange: DateRangeOption) => (dateRange.current = newDateRange),
    [dateRange]
  );

  return (
    <>
      <Row>
        <Box>
          <Typography variant="h4">{t('statistics')}</Typography>
        </Box>
        <Spacer />
        <DateBox
          disabled={loading}
          onEndChange={setEndDate}
          onStartChange={setStartDate}
          onRangeChange={setDateRange}
        />
      </Row>
      <Tabs
        selected={tab}
        onTabChange={setTab}
        hideIfSingle
        tabs={{
          stats: (
            <Suspense
              fallback={
                <GlobalStatsFallback>
                  <CircularProgress />
                </GlobalStatsFallback>
              }
            >
              <GlobalStats
                startDate={startDate}
                endDate={endDate}
                retailer={retailer}
                dateRange={dateRange.current}
              />
            </Suspense>
          ),
          realtime: hasRole(['admin', 'analytics-admin']) ? (
            <Suspense
              fallback={
                <GlobalStatsFallback>
                  <CircularProgress />
                </GlobalStatsFallback>
              }
            >
              <Realtime retailer={retailer} />
            </Suspense>
          ) : null,
          dataExport: hasRole(['admin']) ? (
            <>
              <Suspense fallback={<CircularProgress />}>
                <DataExport
                  startDate={startDate}
                  endDate={endDate}
                  retailer={retailer}
                />
              </Suspense>
              <Suspense fallback={<CircularProgress />}>
                <ReturnsCsvImport />
              </Suspense>
            </>
          ) : null,
          enrichedOrdersOverview: hasRole(['admin']) ? (
            <>
              <Suspense fallback={<CircularProgress />}>
                <EnrichedOrdersOverview
                  startDate={startDate}
                  endDate={endDate}
                  retailer={retailer}
                />
              </Suspense>
            </>
          ) : null,
          experimentStats: hasRole(['admin']) ? (
            <>
              <Suspense fallback={<CircularProgress />}>
                <ExperimentStats
                  startDate={startDate}
                  endDate={endDate}
                  retailer={retailer}
                />
              </Suspense>
            </>
          ) : null,
          storeStatus: hasRole(['admin']) ? (
            <Suspense fallback={<CircularProgress />}>
              <StoreStatus />
            </Suspense>
          ) : null
        }}
      />
    </>
  );
}
